import React, { Component } from 'react';
import {BrowserRouter, Route,MemoryRouter, Switch} from 'react-router-dom';
import './scss/style.scss';
import {ToastContainer} from "react-toastify";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Home = React.lazy(() => import('./views/pages/home/Home'));
const Seja = React.lazy(() => import('./views/pages/seja-franqueado/SejaFranqueado'));
const Somos = React.lazy(() => import('./views/pages/somos/Somos'));
const Contato = React.lazy(() => import('./views/pages/contato/Contato'));
const Privacidade = React.lazy(() => import('./views/pages/privacidade/Privacidade'));
const Moto = React.lazy(() => import('./views/pages/moto/index'));
const Motos = React.lazy(() => import('./views/pages/motos/MotosFull.js'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const Loja = React.lazy(() => import('./views/pages/loja/Loja'));

class App extends Component {

  render() {
    return (
      <BrowserRouter>

          <React.Suspense fallback={loading}>
            <ToastContainer
                style={{zIndex:9999999999999}}
                position="top-right"
                autoClose={3000}/>
            <Switch>
              <Route exact path="/" name="Dermotos" render={props => <Home {...props}/>} />
              <Route exact path="/home" name="Dermotos" render={props => <Home {...props}/>} />
              <Route exact path="/boutique" name="Dermotos" render={props => <Loja {...props}/>} />
              <Route exact path="/contato" name="Dermotos" render={props => <Contato {...props}/>} />
              <Route exact path="/privacidade" name="Dermotos" render={props => <Privacidade {...props}/>} />
              <Route exact path="/register" name="Dermotos" render={props => <Register {...props}/>} />
              <Route exact path="/quemsomos" name="Dermotos" render={props => <Somos {...props}/>} />
              <Route exact path="/moto/:id/:modelo" name="Dermotos moto" render={props => <Moto {...props}/>} />
              <Route exact path="/motos" name="Dermotos motos" render={props => <Motos {...props}/>} />
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/seja-franqueado" name="Seja Franqueado Page" render={props => <Seja {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />

            </Switch>
          </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
